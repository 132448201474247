
import { defineComponent, PropType, ref, inject } from 'vue'

import { message } from 'ant-design-vue'

import { InboxOutlined } from '@ant-design/icons-vue'

import { REPLY } from '@/views/Inbox/config'

import type { UploadProps } from 'ant-design-vue'

import { SetReplyFn, ValueKey } from '@/views/Inbox/Editor/types'

// type GetArrayItemType2<A extends Array<any>> = A[number]
type GetArrayItemType<Item> = Item extends Array<infer T> ? T : never

export default defineComponent({
  name: 'AppUpload',
  props: {
    visible: {
      type: Boolean as PropType<boolean>,
      defauilt: false
    }
  },
  emits: ['update:visible'],
  setup(props, { emit }) {
    const fileList = ref<UploadProps['fileList'][]>([])

    const fn = inject<SetReplyFn>(REPLY)

    const handleCancel = () => {
      fileList.value = []
      emit('update:visible', false)
    }

    /* eslint-disable */
    const beforeUpload: UploadProps['beforeUpload'] = (file: any) => {
      fileList.value = [...fileList.value, file]

      return false
    }

    const saveFile = () => {
      if (fileList.value.length === 0) {
        return message.warning('请上传附件')
      }

      fn?.(ValueKey.Files, fileList.value)

      handleCancel()
    }

    return {
      fileList,
      handleCancel,
      beforeUpload,
      saveFile
    }
  },
  components: {
    InboxOutlined
  }
})
